// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Button, Tab, Tabs } from "@nextui-org/react";
import React, { useEffect, useState } from "react";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './carrousel.css';

export default function Carrousel() {

  const [vehicules, setVehicules] = useState([]);
  const [idAbonnement, setIdAbonnement] = useState(1);

  useEffect(() => {
    let url = `https://evorent.fr/api/vehicules?id_abonnements=${idAbonnement}`;

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) { // Vérification si data est un tableau
          setVehicules(data);
        } else {
          console.error("La réponse de la requête n'est pas un tableau :", data);
        }
      })
      .catch((error) => {
        console.error("Une erreur s'est produite lors de la récupération des véhicules :", error);
      });
    }, [idAbonnement]);

  return (
    <>
      <Swiper
        slidesPerView={1}
        centeredSlides={false}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
          //pauseOnMouseEnter: true,
        }}
        //pagination={{
        //  clickable: true,
        //}}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper" 
      >
        <p className='carrouseltitle'>Explorez les modèles proposés</p>
        <div className='carrouselabo' >
          <Tabs color='secondary' variant='solid' aria-label="Abonnements" radius="full" size='lg' selectedKey={idAbonnement} onSelectionChange={setIdAbonnement}	>
            <Tab key='1' title="Essential"/>
            <Tab key='2' title="One"/>
            <Tab key='3' title="Evo"/>
          </Tabs>
        </div>
        
        {vehicules.map((item, i) => {
          return (
            <SwiperSlide key={i}>
              <img className="carrousel-banner" src={item.lien_image} alt="First slide" />
              <a className="carrouselbuttonmodel" href={`vehicules/${item.uuid}`}><Button className='carrouseltitlemodel' size="lg" radius="full" color="secondary" variant="shadow">Découvrez le {item.marque} {item.modele_court}</Button></a>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}