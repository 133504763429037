import React, { useEffect, useState } from "react";
import { Button, Accordion, AccordionItem, Modal, ModalContent, ModalHeader, ModalBody, useDisclosure } from "@nextui-org/react";
import { useParams } from 'react-router-dom'
import NavBar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation } from 'swiper/modules';
import "./modele.css"

function Modele() {

  let { id } = useParams()
  const [modele, setModele] = useState({})
  const {isOpen, onOpen, onClose} = useDisclosure();

  useEffect(() => {
    fetch(`https://evorent.fr/api/modele/${id}`)
      .then((res) => res.json()) // en cas de réussite de la requête
      .then((data) => {
        const modeleData = Array.isArray(data) ? data[0] : data;
        setModele(modeleData);
      })
    .catch(err => {
      // en cas d’échec de la requête
      console.log(err);
    })
  },[id]);

  const img_link = `../${modele.lien_image}`
  
  return(
    <>
      <NavBar/>
        <Modal backdrop="blur"  className="modalmodele" isOpen={isOpen} onClose={onClose}>
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">{modele.marque} {modele.modele}</ModalHeader>
                <ModalBody>
                <Swiper navigation={true} modules={[Navigation]} className="modeleSwiper">
                  <SwiperSlide><img className="modeleirl" style={{objectFit: "contain",width: "fit-content"}} src={`/${modele.irl_images}-1.JPG`} alt={modele.modele_court} /></SwiperSlide>
                  <SwiperSlide><img className="modeleirl" style={{objectFit: "contain",width: "fit-content"}} src={`/${modele.irl_images}-2.JPG`} alt={modele.modele_court} /></SwiperSlide>
                  <SwiperSlide><img className="modeleirl" style={{objectFit: "contain",width: "fit-content"}} src={`/${modele.irl_images}-3.JPG`} alt={modele.modele_court} /></SwiperSlide>
                </Swiper>
                </ModalBody>
              </>
            )}
          </ModalContent>
        </Modal>

      <div className="presentationvehicule" >
        <div className="grid grid-cols-12">
          <div className="sm:col-span-8 xl:col-span-9 col-span-12">
            <h1 className="nommodele">{modele.marque} {modele.modele}</h1>
            <p className="textemodele">Inclus dans la formule {modele.nom}</p>
            <img class="imgmodele" src={img_link} alt="Véhicule"/>
            {modele.irl_images !== null &&
              <Button size="lg" color="secondary" onClick={onOpen} className="visiophoto">
                Galerie photo
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0"/>
                  <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0"/>
                </svg>
              </Button>    
              
            }
          </div>
          <div className="sm:col-span-4 xl:col-span-3 col-span-12 infostarifs">
            <p className="titreinfostarifs">{modele.prix}€</p>
            <p>par mois sans apport</p>
            <p className="font-bold">({modele.prixht}€ HT)</p>
            {modele.id_abonnements === 3
              ? <Button isDisabled className="bg-gradient-to-tr from-purple-700 to-pink-400 text-white shadow-lg btnabonnement" radius="full" >Bientôt disponible</Button>
              : <a href={`/subscribe/${modele.uuid}`}><Button className="bg-gradient-to-tr from-purple-700 to-pink-400 text-white shadow-lg btnabonnement" radius="full" >S'abonner</Button></a>
            }
            
            <Accordion color="secondary" variant="shadow" className="accordeontext">
            <AccordionItem key="1" title="Conditions">
              <p>Caution : {modele.caution}€</p>
              <p>Kilometrage inclus : {modele.kilometrage}km</p>
              {modele.id_abonnements === 4 && (
                <p class="badge2">Pro VTC uniquement</p>
              )}
            </AccordionItem>
            <AccordionItem key="2" title="Informations principales">
              <p>Puissance : {modele.puissance}</p>
              <p>Carburant : {modele.carburant}</p>
              <p>Consommations : {modele.consommation}</p>
              <p>Poids : {modele.poids}</p>
            </AccordionItem>
            </Accordion>
          </div>
        </div>
        
      </div>
      <div className="specvehicules">
        <div className="drive">
          <h1 className="titremodele text-white">Drive</h1>
          <dl class="divide-y divide-gray-100">
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt class="text-lg font-medium text-white">Puissance</dt>
              <dd class="mt-1 leading-6 text-white sm:col-span-2 sm:mt-0">{modele.puissance}</dd>
            </div>
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt class="text-lg font-medium text-white">Accélération 0 à 100km/h</dt>
              <dd class="mt-1 leading-6 text-white sm:col-span-2 sm:mt-0">{modele.acceleration}</dd>
            </div>
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt class="text-lg font-medium text-white">Vitesse maximale</dt>
              <dd class="mt-1 leading-6 text-white sm:col-span-2 sm:mt-0">{modele.vmax}</dd>
            </div>
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt class="text-lg font-medium text-white">Boîte de vitesse</dt>
              <dd class="mt-1 leading-6 text-white sm:col-span-2 sm:mt-0">{modele.boite}</dd>
            </div>
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt class="text-lg font-medium text-white">Carburant</dt>
              <dd class="mt-1 leading-6 text-white sm:col-span-2 sm:mt-0">{modele.carburant}</dd>
            </div>
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt class="text-lg font-medium text-white">Transmission</dt>
              <dd class="mt-1 leading-6 text-white sm:col-span-2 sm:mt-0">{modele.transmission}</dd>
            </div>
          </dl>
        </div>
        <div className="consommation">
          <h1 className="titremodele text-white">Consommations / Emissions de CO²</h1>
          <dl class="divide-y divide-gray-100">
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt class="text-lg font-medium text-white">Consommation de carburant (pondérées, conditions mixtes)</dt>
              <dd class="mt-1 leading-6 text-white sm:col-span-2 sm:mt-0">{modele.consommation}</dd>
            </div>
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt class="text-lg font-medium text-white">Autonomie</dt>
              <dd class="mt-1 leading-6 text-white sm:col-span-2 sm:mt-0">{modele.autonomie}</dd>
            </div>
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt class="text-lg font-medium text-white">Taille réservoir et/ou batterie</dt>
              <dd class="mt-1 leading-6 text-white sm:col-span-2 sm:mt-0">{modele.reservoir}</dd>
            </div>
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt class="text-lg font-medium text-white">Emission de CO²</dt>
              <dd class="mt-1 leading-6 text-white sm:col-span-2 sm:mt-0">{modele.co2}</dd>
            </div>
          </dl>
        </div>
        <div className="mensurations">
          <h1 className="titremodele text-white">Mensurations et poids</h1>
          <dl class="divide-y divide-gray-100">
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt class="text-lg font-medium text-white">Poids</dt>
              <dd class="mt-1 leading-6 text-white sm:col-span-2 sm:mt-0">{modele.poids}</dd>
            </div>
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt class="text-lg font-medium text-white">Longueur</dt>
              <dd class="mt-1 leading-6 text-white sm:col-span-2 sm:mt-0">{modele.longueur}</dd>
            </div>
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt class="text-lg font-medium text-white">Largeur</dt>
              <dd class="mt-1 leading-6 text-white sm:col-span-2 sm:mt-0">{modele.largeur}</dd>
            </div>
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt class="text-lg font-medium text-white">Hauteur</dt>
              <dd class="mt-1 leading-6 text-white sm:col-span-2 sm:mt-0">{modele.hauteur}</dd>
            </div>
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt class="text-lg font-medium text-white">Poids à vide</dt>
              <dd class="mt-1 leading-6 text-white sm:col-span-2 sm:mt-0">{modele.poids}</dd>
            </div>
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt class="text-lg font-medium text-white">Coffre</dt>
              <dd class="mt-1 leading-6 text-white sm:col-span-2 sm:mt-0">{modele.coffre}</dd>
            </div>
          </dl>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default Modele;