import NavBar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import "./entreprise.css";

function Entreprise(){
  /*
      <div>
      <video className="video" playsInline autoPlay loop muted>
        <source  src="videos/bmw.mp4" type="video/mp4"/>
      </video>
      <p className="videotitle">Explorez la mobilité autrement avec EvoRent</p>
    </div>
  */
  return(
    <>
    <NavBar/>
    <div className="entreprisepart">
    <div className="flex justify-center text-indigo-600 m-10 h-20">
        <img src="/evorent-white.png"/>
      </div>
      <p className="entreprisetext">EvoRent, c'est la solution innovante de mobilité flexible qui révolutionne votre expérience automobile ! Oubliez les contraintes du leasing traditionnel et découvrez une alternative simple, transparente et engagée.</p>
      <br/><p className="entreprisetext">Née de l'ambition commune entre 3 co-fondateurs, EvoRent répond aux besoins d'une mobilité résolument plus flexible. Nous proposons plus qu'un simple service, mais une expérience inédite. L'accessibilité, la transparence et l'innovation sont nos maîtres-mots.</p>
      <br/><p className="entreprisetext">Souscrivez à nos formules mensuelles et accédez à une flotte de véhicules variée. Notre tarification claire et transparente élimine les frais cachés pour une expérience sans surprise</p>
      <br/><p className="entreprisetext">Soucieux de l'environnement, nous mettons à votre disposition une gamme de véhicules électriques et hybrides afin de contribuer à un avenir plus propre en choisissant une mobilité durable.</p>
      <br/><p className="entreprisetext">EvoRent : notre engagement, votre liberté.</p>    
      <img class="entreprisecar" src="voitures/BMW/serie3-3.png" alt="" />
    </div>
    <div className="entreprisepart bg-black">
      <h1 className="titre1 text-white">Pourquoi choisir EvoRent ?</h1>
      <p className="entreprisetext text-white"><b>Flexibilité Sans Engagement :</b> Changez de véhicule chaque mois selon vos besoins.
        <br/><b>Tarification Transparente :</b> Pas de coûts cachés, tout est inclus dans votre abonnement mensuel.
        <br/><b>Service Client Exceptionnel :</b> Notre équipe est là pour vous accompagner à chaque étape.
        <br/><b>Engagement Environnemental :</b> Contribuez à un avenir plus durable en conduisant des véhicules respectueux de l'environnement.
      </p>
      <br/>
      <p className="entreprisetext2 text-white">Rejoignez la Révolution de la Mobilité avec EvoRent !</p>
      <img class="entreprisecar" src="voitures/Tesla/model3-4.png" alt="" />
    </div>
    <Footer/>
    </>
  );
}
export default Entreprise;