import React from "react";
import {Navbar, NavbarBrand, NavbarContent, NavbarMenu, NavbarMenuToggle, NavbarMenuItem, NavbarItem, Link, Button} from "@nextui-org/react";

export default function NavBar() {

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <Navbar shouldHideOnScroll onMenuOpenChange={setIsMenuOpen} className="bg-black shadow-lg shadow-black-500/50">
    <NavbarContent>
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="sm:hidden"
          style={{color: "white"}}
        />
        <NavbarBrand>
          <a href="/"><img src="/evorent2.png" alt="EvoRent logo" className="logo"/></a>
          {/*<a href="/"><p className="font-bold text-white text-inherit">EvoRent</p></a>*/}
        </NavbarBrand>
      </NavbarContent>
      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        <NavbarItem>
          <Link className="text-sm text-white" href="/abonnement">
            Nos abonnements
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link className="text-sm text-white" href="/vehicules">
            Nos véhicules
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link className="text-sm text-white" href="/entreprise">
            Notre entreprise
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link className="text-sm text-white" href="/contact">
            Nous contacter
          </Link>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent justify="end">
        <NavbarItem>
          <Button as={Link} color="secondary" href="/moncompte" variant="shadow" isDisabled>
            Mon compte
          </Button>
        </NavbarItem>
      </NavbarContent>
      <NavbarMenu className="bg-black">
        <NavbarMenuItem>
          <NavbarItem>
            <Link className="w-full text-white text-xl mb-3" href="/abonnement">
              Nos abonnements
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link className="w-full text-white text-xl mb-3" href="/vehicules">
              Nos véhicules
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link className="w-full text-white text-xl mb-3" href="/entreprise">
              Notre entreprise
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link className="w-full text-white text-xl mb-3" href="/contact">
              Nous contacter
            </Link>
          </NavbarItem>
        </NavbarMenuItem>
      </NavbarMenu>
    </Navbar>
  );
}
