import React, { useEffect, useState } from "react";
import NavBar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import "./homepage.css"
import {Button} from "@nextui-org/react";

function HomePage() {

  const [prime, setPrime] = useState([]);

  useEffect(() => {
    fetch('https://evorent.fr/api/prime')
      .then((res) => res.json()) // en cas de réussite de la requête
      .then((data) => {
        setPrime(data)
      })
    .catch(err => {
      // en cas d’échec de la requête
      console.log(err);
    })
  },[]);

    return (
        <>
          <NavBar/>
          <div>
          <video className="banner" playsInline autoPlay loop muted>
            <source  src="videos/home-route.mp4" type="video/mp4"/>
          </video>
            <p className="bannertitle">Explorez la mobilité autrement avec EvoRent</p>
          </div>
          <div className="discover">
            <img className="itemshome" src="homecars.png" alt="" />
            <h1 className="titre1 text-center text-white">Trouvez la formule d'abonnement qui vous correspond</h1>
            <a href="/abonnement"><Button size="lg" className="bg-gradient-to-tr from-purple-700 to-pink-400 text-white shadow-lg itemshome btnhome" radius="full" >Découvrir nos abonnements</Button></a>
          </div>
          <div className="discover2">
            <h1 className="titre1 text-center">Vous êtes VTC ? Une offre parfaite pour vous.</h1>
            <div className="grid grid-cols-12 mt-28 mb-28">
              <div className="sm:col-span-6 xl:col-span-6 col-span-12 itemshome">
                <img className="object-contain" src="carsvtc.png" alt="" />
              </div>
              <div className="sm:col-span-6 xl:col-span-6 col-span-12">
                <p className="text-center text-2xl mt-8 mb-4">7000km par mois tout compris</p>
                <p className="text-center vtcprix">1040€ HT</p>
                <a href="/vehicules?id_abonnements=4"><Button size="lg" className="bg-gradient-to-tr from-purple-700 to-pink-400 text-white shadow-lg itemshome btnhome" radius="full" >Découvrir notre offre pour les VTC</Button></a>
              </div>
            </div>
            <div className="flex flex-row flex-wrap justify-evenly">
              <img className="vtclogo" src="uber.png" alt="" />
              <img className="vtclogo" src="bolt.png" alt="" />
              <img className="vtclogo" src="heetch.png" alt="" />
              <img className="vtclogo" src="freenow.png" alt="" />
            </div>
          </div>
          <div className="new">
            <p className="titre1">Découvrez nos dernières nouveautées</p>
            <div className="flex flex-row flex-wrap justify-center mb-10">
            {prime.map((item, i) => {
              return (
                <div key={i} class="max-w-sm cartevehicules">
                  <img class="cardimgvehicules" src={item.lien_image} alt="" />
                  <h1 class="cardtitrevehicules mt-10 text-center text-2xl">{item.marque} {item.modele_court}</h1>
                  <a class="cover-link" href={`vehicules/${item.uuid}`}/>
              </div>
              );
            })}
          </div>
          </div>
          <Footer/>
        </>
    );
  }

  export default HomePage;