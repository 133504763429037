import React, { useEffect, useState } from "react";
import NavBar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import "./vehicules.css"
import { Button, Select, SelectItem } from "@nextui-org/react";
import { useLocation } from 'react-router-dom';

function Vehicules() {
  
  const [vehicules, setVehicules] = useState([]);
  const [filters, setFilters] = useState([]);
  const [abonnement, setAbonnement] = useState([]);
  const [idAbonnement, setIdAbonnement] = useState('');
  const [marque, setMarque] = useState('');
  const [type, setType] = useState('');
  const [carburant, setCarburant] = useState('');
  const { search } = useLocation();

  // Mettre à jour les états en fonction des paramètres de l'URL
  useEffect(() => {
    const params = new URLSearchParams(search);
    const idAbonnementParam = params.get('id_abonnements');
    if (idAbonnementParam) setIdAbonnement(idAbonnementParam);
    // Ajoutez d'autres conditions pour d'autres paramètres d'URL si nécessaire
  }, [search]);

  useEffect(() => {
    let url = 'https://evorent.fr/api/vehicules';
    
    // Ajouter les paramètres de filtre s'ils sont définis
    if (idAbonnement || marque || type || carburant) {
      url += `?`;
      if (idAbonnement) url += `id_abonnements=${idAbonnement}&`;
      if (marque) url += `marque=${marque}&`;
      if (type) url += `type=${type}&`;
      if (carburant) url += `carburant=${carburant}&`;
      // Supprimer le dernier '&' si présent
      url = url.slice(0, -1);
    }
  
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setVehicules(data);
      });
  }, [idAbonnement, marque, type, carburant]);

  useEffect(() => {
    fetch('https://evorent.fr/api/abonnements')
      .then((res) => res.json()) // en cas de réussite de la requête
      .then((data) => {
        setAbonnement(data)
      })
  },[]);

  useEffect(() => {
    fetch('https://evorent.fr/api/vehicules')
      .then((res) => res.json()) // en cas de réussite de la requête
      .then((data) => {
        setFilters(data)
      })
  },[]);

  const handleIdAbonnement= (e) => {
    setIdAbonnement(e.target.value);
  };

  const handleMarque= (e) => {
    setMarque(e.target.value);
  };

  const handleType= (e) => {
    setType(e.target.value);
  };

  const handleCarburant= (e) => {
    setCarburant(e.target.value);
  };

  return (
    <>
      <NavBar/>
      <div className="fond">
        <h1 className="titre1"> Nos véhicules</h1>
        <p className="titrevehicules">Cliquez sur le véhicule que vous souhaitez découvrir !</p>
        <div key="faded" className="combo flex w-full flex-wrap md:flex-nowrap gap-4 items-center">
          <Select color="secondary" label="Abonnement" allowsCustomValue="false" className="w-full"  onClear="true" onChange={handleIdAbonnement}>
            {abonnement.map((abonnement) => (
            <SelectItem key={abonnement.id} value={abonnement.id}>
              {abonnement.nom}
            </SelectItem>
          ))}
          </Select>
          <Select color="secondary" label="Marque" className="w-full" onChange={handleMarque}>
            {Array.from(new Set(filters.map(v => v.marque))) // Créer un ensemble des marques uniques
            .sort((a, b) => a.localeCompare(b)) // Trie les marques dans l'ordre alphabétique
            .map(marque => (
              <SelectItem key={marque} value={marque}>
                {marque}
              </SelectItem>
            ))}
          </Select>
          <Select color="secondary" label="Type de véhicule" className="w-full" onChange={handleType}>
            {Array.from(new Set(filters.map(t => t.type))) // Créer un ensemble des marques uniques
            .sort((a, b) => a.localeCompare(b)) // Trie les marques dans l'ordre alphabétique
            .map(type => (
              <SelectItem key={type} value={type}>
                {type}
              </SelectItem>
            ))}
          </Select>
          <Select color="secondary" label="Carburant" className="w-full" onChange={handleCarburant}>
            {Array.from(new Set(filters.map(c => c.carburant))) // Créer un ensemble des marques uniques
            .sort((a, b) => a.localeCompare(b)) // Trie les marques dans l'ordre alphabétique
            .map(carburant => (
              <SelectItem key={carburant} value={carburant}>
                {carburant}
              </SelectItem>
            ))}
          </Select>
          <a href="/vehicules"><Button color="secondary" variant="solid" className="resetbtn shadow-sm ">Réinitialiser</Button></a>
        </div>
        
        <div className="flex flex-row flex-wrap justify-center mb-10 vehicules">
        {vehicules.map((item, i) => {
          return (
            <div key={i} class="cartevehicules">
              <img class="cardimgvehicules" src={item.lien_image} alt="Voitures" />
              <h1 class="cardtitrevehicules text-center text-2xl">{item.marque} {item.modele_court}</h1>
              {item.id_abonnements === 4 && (
                <p class="badge">Pro VTC uniquement</p>
              )}
              <a class="cover-link" href={`vehicules/${item.uuid}`} />
            </div>
          );
        })}
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default Vehicules;