import React, { useEffect, useState } from "react";
import { Input, Button, Textarea, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Select, SelectItem} from "@nextui-org/react";
import { useParams } from 'react-router-dom'
import NavBar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import "./subscribe.css";
import ReCAPTCHA from "react-google-recaptcha";
//6Lf-l5ApAAAAAAFg3muMzHy1PmFKJdKvt9VzZd0R

function Subscribe() {

  //Affichage du modele
  let { id } = useParams()
  const [modele, setModele] = useState({})
  const img_link = `../${modele.lien_image}`
  const [modalOpen, setModalOpen] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null); //Google reCaptcha

  //Données du vehicules
  useEffect(() => {
    fetch(`https://evorent.fr/api/modele/${id}`)
      .then((res) => res.json()) // en cas de réussite de la requête
      .then((data) => {
        const modeleData = Array.isArray(data) ? data[0] : data;
        setModele(modeleData);
      })
    .catch(err => {
      // en cas d’échec de la requête
      console.log(err);
    })
  },[id]);

  //Gestion du formulaire
  const [formData, setFormData] = useState({
    vehicules: '',
    civilite: '',
    nom: '',
    prenom: '',
    email: '',
    num: '',
    codepostal: '',
    ville: '',
    remarques: ''
  });

  //Recupération des données formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //console.log(JSON.stringify(formData, null, 2));

    fetch('https://evorent.fr/api/contact-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => {
      if (response.ok) {
        console.log('Données envoyées avec succès !');
        // Réinitialiser le formulaire si nécessaire
        setFormData({
          vehicules: '',
          civilite: '',
          nom: '',
          prenom: '',
          societe: '',
          email: '',
          num: '',
          codepostal: '',
          ville: '',
          remarques: ''
        });
        setModalOpen(true);
      } else {
        console.error('Erreur lors de l\'envoi des données.');
      }
    })
    .catch(error => {
      console.error('Erreur lors de l\'envoi des données : ', error);
    });
  };

  return (
    <>
    <NavBar/>
    <div className="subscribevehicule" >
      <div className="grid grid-cols-12">
        <div className="sm:col-span-8 xl:col-span-9 col-span-12">
          <h1 className="nommodele">S'abonner à la Formule {modele.nom}</h1>
          <p className="textemodele">{modele.marque} {modele.modele} </p>
          <p className="textemodele2">{modele.prix}€ par mois sans apport ({modele.prixht}€ HT)</p>
          {modele.id_abonnements === 4 && (
            <p class="badge2">Pro VTC uniquement</p>
          )}
        </div>
        <div className="sm:col-span-4 xl:col-span-3 col-span-12">
          <img class="imgsubscribe" src={img_link} alt="Véhicule"/>
        </div>
      </div>
      <form className="formsubscribe" onSubmit={handleSubmit}>
        <p className="mt-20 mb-10 text-lg leading-8 text-gray-600 text-center">
          Si vous souhaitez profiter de nos offres, merci de completer ce formulaire et vous serez ainsi recontacter par notre équipe.
        </p>
        <input style={{display:"none"}} name="vehicules" value={formData.vehicules = `Formule ${modele.nom} pour le ${modele.marque} ${modele.modele}`} onChange={handleChange}/>
        <Select isRequired name="civilite" label="Civilité" color="secondary" className="forminput2" onChange={handleChange}>
          <SelectItem key="Madame" value="Madame">Madame</SelectItem>
          <SelectItem key="Monsieur" value="Monsieur">Monsieur</SelectItem>
        </Select>
        <Input isRequired type="text" name="nom" label="Nom" pattern="^[a-zA-ZÀ-ÿ\-']{2,}(?: [a-zA-ZÀ-ÿ\-']{2,})?$" color="secondary" className="forminput" value={formData.nom} onChange={handleChange}/>
        <Input isRequired type="text" name="prenom" label="Prenom" pattern="^[a-zA-ZÀ-ÿ\-']{2,}(?: [a-zA-ZÀ-ÿ\-']{2,})?$" color="secondary" className="forminput" value={formData.prenom} onChange={handleChange}/>
        <Input type="text" name="societe" label="Société" pattern="^[a-zA-ZÀ-ÿ\-']{2,}(?: [a-zA-ZÀ-ÿ\-']{2,})?$" color="secondary" className="forminput" value={formData.societe} onChange={handleChange}/>
        <Input isInvalid={false} isRequired type="email" name="email" label="Email" pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$" color="secondary" className="forminput" value={formData.email} onChange={handleChange}/>
        <Input isRequired type="tel" name="num" label="Numéro de téléphone" pattern="(0|\\+33|0033)[1-9][0-9]{8}" color="secondary" className="forminput" value={formData.num} onChange={handleChange}/>
        <Input isRequired type="text" name="codepostal" label="Code postal" pattern="\d{5}(-\d{4})?" color="secondary" className="forminput" value={formData.codepostal} onChange={handleChange} />
        <Input isRequired type="text" name="ville" label="Ville de résidence" pattern="^[a-zA-ZÀ-ÿ\-']{2,}(?: [a-zA-ZÀ-ÿ\-']{2,})?$" color="secondary" className="forminput" value={formData.ville} onChange={handleChange}/>
        <Textarea name="remarques" label="Remarques ou questions supplementaires ? N'hesitez pas aussi à préciser quand vous souhaitez être recontacté." color="secondary" className="forminput" value={formData.remarques} onChange={handleChange}/>
        <ReCAPTCHA
          className="forminput"
          sitekey="6Lf-l5ApAAAAAAFg3muMzHy1PmFKJdKvt9VzZd0R"
          onChange={(val) => setCaptchaValue(val)}
        />
        <Button isLoading={!captchaValue} className="bg-gradient-to-tr from-purple-700 to-pink-400 text-white shadow-lg btnsubmit" variant="shadow" type="submit" value="Submit" >Envoyer</Button>
      </form>
    </div>
      <Modal backdrop="blur" isOpen={modalOpen}>
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">Demande d'abonnement envoyé</ModalHeader>
          <ModalBody>
            <p> 
              Le formulaire a bien été envoyé vers nos services. Vous serez recontacter prochainement par un conseillé EvoRent.
            </p>
          </ModalBody>
          <ModalFooter>
            <a href="/"><Button color="danger" variant="shadow">Revenir à l'accueil</Button></a>
          </ModalFooter>
        </ModalContent>
      </Modal>
    <Footer/>
    </>
  )
}

export default Subscribe;