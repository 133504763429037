import "./contact.css";
import NavBar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";

function Contacter(){
  return(
    <>
    <NavBar/>
    <div className="min-h-screen">
      <h1 className="titre1"> Nous contacter</h1>
      <div className="contact">
        <h1 className="titrecontact">Besoin d'aide ?</h1>
        <img class="callimg" src="voitures/BMW/serie3-4.png" alt="" />
        <p className="textecontact">Votre véhicule arrive à l'échéance d'un entretien ? Vous souhaitez déclarer un sinistre ? D'autres renseignements ?</p>
        <p className="textecontact">Nous sommes disponible par téléphone du lundi au samedi de 9h à 19h.</p>
        <a href="tel:0766033954"><p className="numcontact">07 66 03 39 54</p></a>
      </div>
    </div>
    <Footer/>
    </>
  );
}

export default Contacter;