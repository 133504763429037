import * as React from "react";
import {NextUIProvider} from "@nextui-org/react";
import { 
  BrowserRouter, 
  Route,
  Routes
} from "react-router-dom";
import './App.css';
import HomePage from "./pages/homepage/homepage";
import Abonnements from "./pages/abonnement/abonnement";
import Vehicules from "./pages/vehicules/vehicules";
import Contact from "./pages/contact/contact";
import Entreprise from "./pages/entreprise/entreprise";
import Modele from "./pages/modele/modele";
import MonCompte from "./pages/moncompte/moncompte";
import Subscribe from "./pages/subscribe/subscribe";

function App() {
  return (
    <NextUIProvider>
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" Component={ HomePage } />
        <Route path="/abonnement" Component={ Abonnements } />
        <Route path="/vehicules" Component={ Vehicules } />
        <Route path="/vehicules/:id" Component={ Modele } />
        <Route path="/entreprise" Component={ Entreprise } />
        <Route path="/contact" Component={ Contact } />
        <Route path="/moncompte" Component={ MonCompte } />
        <Route path="/subscribe/:id" Component={ Subscribe } />
      </Routes>
    </BrowserRouter>
    </NextUIProvider>
  );
}

export default App;
