import React, { useState } from "react";
import NavBar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import Carrousel from "../../components/carrousel/carrousel";
import "./abonnement.css"
import { Button, Accordion, AccordionItem, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@nextui-org/react";

function Abonnements() {
    const [modalOpen, setModalOpen] = useState(false);

    //Gestion du formulaire
    const [formData, setFormData] = useState({
      nom: '',
      email: '',
      texte: ''
    });

  //Recupération des données formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //console.log(JSON.stringify(formData, null, 2));

    fetch('https://evorent.fr/api/infos-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => {
      if (response.ok) {
        console.log('Données envoyées avec succès !');
        // Réinitialiser le formulaire si nécessaire
        setFormData({
          nom: '',
          email: '',
          texte: ''
        });
        setModalOpen(true);
      } else {
        console.error('Erreur lors de l\'envoi des données.');
      }
    })
    .catch(error => {
      console.error('Erreur lors de l\'envoi des données : ', error);
    });
  };

  return (
    <>
      <NavBar />
      {/* Partie avec les 3 cases */}
      <h1 className="titre1"> Découvrez la mobilité sans contraintes grâce à notre abonnement mensuel.</h1>
      <div className="flex flex-row flex-wrap justify-center">
        <div className="carte">
          <h1 className="cardtitre mt-10 text-center text-2xl">Sans engagement</h1>
          <img className="cardimg" src="voitures/Mercedes-Benz/classea-2.png" alt="" />
        </div>
        <div className="carte">
          <h1 className="cardtitre mt-10 text-center text-2xl">Flexible</h1>
          <img className="cardimg" src="voitures/Porsche/macan-2.png" alt="" />
        </div>
        <div className="carte">
          <h1 className="cardtitre mt-10 text-center text-2xl">Simple</h1>
          <img className="cardimg" src="voitures/Tesla/model3-5.png" alt="" />
        </div>
      </div>
      {/* Partie avec les checks */}
      <p className="titre1">L'abonnement qui a tout compris.</p>
      <div className="flex flex-row flex-wrap justify-center mb-10">
        <div className="checktext">
          <h2 className="text-2xl font-bold">Assurance 🚨</h2>
          <p className="">Pour garantir votre tranquillité d'esprit, nous proposons une assurance complète incluse dans chaque contrat de location. Notre assurance couvre tous les aspects de votre expérience de conduite, vous offrant une protection maximale contre les imprévus.</p>
        </div>
        <div className="checktext">
          <h2 className="text-2xl font-bold">Assistance ⛑</h2>
          <p className="">Notre équipe dévouée est disponible 24h/24 et 7j/7, pour vous offrir une assistance en cas d'urgence ou de besoin. Que ce soit pour un dépannage, une question technique ou une simple demande d'information, nous sommes toujours là pour vous aider, où que vous soyez.</p>
        </div>
        <div className="checktext">
          <h2 className="text-2xl font-bold">Frais 💲</h2>
          <p className="">Chez Evorent, la transparence est notre priorité. Nos contrats de location incluent tous les frais associés à l'utilisation de votre véhicule premium, sans frais cachés ni surprises désagréables. Vous saurez toujours exactement ce que vous payez, avec des tarifs compétitifs et clairs.</p>
        </div>
        <div className="checktext">
          <h2 className="text-2xl font-bold">Entretien 🔧</h2>
          <p className="">Pour garantir les performances optimales de votre véhicule premium, nous prenons en charge tous les frais d'entretien régulier. De la maintenance mécanique aux contrôles techniques, notre équipe d'experts veille à ce que votre voiture soit toujours en parfait état.</p>
        </div>
        <div className="checktext">
          <h2 className="text-2xl font-bold">Livraison 📌</h2>
          <p className="">Votre temps est précieux, c'est pourquoi nous proposons un service de livraison personnalisé. Que vous souhaitiez recevoir votre véhicule à votre domicile ou le récuperer, notre équipe se charge de tout pour que vous puissiez prendre la route dès que possible.</p>
        </div>
      </div>
      {/* Partie avec les Cards avantages */}
      <div className="avantages bg-black shadow-lg">
        <div className="flex flex-row flex-wrap justify-center">
          <h1 className="titre1 text-white"> Bien au-delà d'un simple service, une expérience automobile unique.</h1>
          <div className="carte2">
            <h1 className="cardtitre2 text-center">Jusqu'à 4 changements de vehicules par an<br /> Faites une pause quand vous le souhaitez<br /></h1>
            <img className="cardimg2" src="echange.png" alt="Car" />
          </div>
          <div className="carte2">
            <h1 className="cardtitre2 text-center">Louez sans apport et sans conditions<br />Testez la mobilité hybride et électrique</h1>
            <img className="cardimg2" src="voitures/Volkswagen/golf8-3.png" alt="Car" />
          </div>
        </div>
      </div>
      {/*Partie carousel*/}
      <Carrousel />
      {/*Partie choix abonnement*/}
      <div className="w-full h-full mx-auto bg-black px-5 py-10 mt-10 mb-10">
        <div className="text-center max-w-xl mx-auto mt-20">
          <h1 className="titre1 text-white">Nos abonnemements</h1>
          <h3 className="text-xl text-white font-medium mt-10 mb-10">Choisissez parmi nos offres d'abonnements </h3>
        </div>
        <div className="max-w-4xl bottom-0 mx-auto md:flex mb-28">
          <div className="w-full md:w-1/3 md:max-w-none bg-white px-8 py-8 md:py-10 mb-3 mx-auto md:mt-14 offercarte shadow-lg shadow-gray-600 md:flex md:flex-col">
            <div className="w-full flex-grow">
              <h2 className="text-center font-bold text-4xl md:text-5xl mb-5">Essential</h2>
              <h3 className="text-center font-bold text-xl">À partir de 999€</h3>
              <h3 className="text-center">(799€ HT)</h3>
              <ul className="text-sm px-3 mt-8 mb-8">
                <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i>Véhicules hauts de gamme</li>
                <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i>Soyez dans l'ère du temps avec notre porte d'entrée vers nos véhicules premium</li>
              </ul>
            </div>
            <div className="w-full">
            <a href="/vehicules?id_abonnements=1"><Button size="lg" className="bg-gradient-to-tr from-purple-700 to-pink-400 text-white shadow-lg itemshome w-9/12" radius="full" color="secondary" variant="shadow">S'abonner</Button></a>
            </div>
          </div>
          <div className="w-full md:w-1/3 md:max-w-none bg-white px-8 py-8 md:py-10 mb-3 mx-auto md:-mx-6 md:mt-3 offercarte shadow-lg shadow-gray-600 md:relative md:z-1 md:flex md:flex-col">
            <p class="notif">Le plus populaire !</p>
            <div className="w-full flex-grow">
              <h2 className="text-center font-bold text-4xl md:text-5xl mb-5">One</h2>
              <h3 className="text-center font-bold text-xl">À partir de 1299€</h3>
              <h3 className="text-center">(1040€ HT)</h3>
              <ul className="text-sm px-3 mt-8 mb-8">
                <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i>Les véhicules luxueux et spacieux de l'abonnement</li>
                <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i>Passez à un niveau superieur avec plus de confort et d'élégance</li>
              </ul>
            </div>
            <div className="w-full">
            <a href="/vehicules?id_abonnements=2"><Button size="lg" className="bg-gradient-to-tr from-purple-700 to-pink-400 text-white shadow-lg itemshome w-9/12" radius="full" color="secondary" variant="shadow">S'abonner</Button></a>
            </div>
          </div>
          <div className="w-full md:w-1/3 md:max-w-none bg-white px-8 py-8 md:py-10 mb-3 mx-auto md:mt-20 offercarte shadow-lg shadow-gray-600 md:flex md:flex-col">
            <div className="w-full flex-grow">
              <h2 className="text-center font-bold text-4xl md:text-5xl mb-5">Evo</h2>
              <h3 className="text-center font-bold text-xl">A partir de 1899€</h3>
              <h3 className="text-center">(1520€ HT)</h3>
              <ul className="text-sm px-3 mt-8 mb-8">
                <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i>La sportivité et l'exclusif pour vous</li>
                <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i>Adopter un plaisir de conduite inégalé dans un luxe tout compris</li>
              </ul>
            </div>
            <div className="w-full">
              <a href="/vehicules?id_abonnements=3"><Button size="lg" className="bg-gradient-to-tr from-purple-700 to-pink-400 text-white shadow-lg itemshome w-9/12" radius="full" color="secondary" variant="shadow">S'abonner</Button></a>
            </div>
          </div>
        </div>
      </div>
      {/* Partie FAQ */}
      <h1 className="titre1"> Vous avez des questions ? Nous avons des réponses !</h1>
      <div className="accordeon">
        <Accordion>
          <AccordionItem key="1" aria-label="Accordion 1" title="Comment souscrire à l’abonnement EvoRent ?">
            <p>1. Sélectionnez votre abonnement en cliquant sur le bouton "S'abonner" sur la page dédiée, puis remplissez le formulaire d'inscription.
              <br />2. Un membre de notre équipe vous contactera pour vous guider à travers les démarches administratives.
              <br />3. Recevez le contrat d'abonnement directement dans votre boîte mail, signez-le en ligne à votre convenance.
              <br />4. Procédez au paiement de votre abonnement lors de la signature du contrat et effectuez la réservation de votre premier véhicule.
              <br />Votre abonnement sera activé à partir de la date de prise en charge spécifiée dans le contrat.
            </p>
          </AccordionItem>
          <AccordionItem key="2" aria-label="Accordion 2" title="La livraison à domicile est-elle payante ?">
            <p>La livraison de votre véhicule à domicile est possible avec l'abonnement moyennant un tarif de 1,5€ HTVA/km parcourut par notre transporteur au départ de Paris en France.
              <br />Vous pouvez également retirer le véhicule gratuitement à Paris en France.</p>
          </AccordionItem>
          <AccordionItem key="3" aria-label="Accordion 3" title="Combien de km/mois sont-ils inclus dans l’abonnement ?">
            <p>La limite kilométrique mensuelle autorisée est de 2 000 km. Les frais additionnels pour les kilomètres excédentaires sont calculés en fonction de la formule d’abonnement sélectionnée.</p>
          </AccordionItem>
          <AccordionItem key="4" aria-label="Accordion 4" title="Dois-je payer la caution ?">
            <p>Avec notre abonnement, vous disposez de cautions réduites :
              <br />Essential - 1000 €
              <br />One - 2000 €
              <br />Evo - 3000 €
              <br />Les cautions sont payables uniquement par virement bancaire.</p>
          </AccordionItem>
          <AccordionItem key="5" aria-label="Accordion 5" title="Combien de fois puis-je changer de voitures ?">
            <p>Vous pouvez changer de véhicules jusqu’à 4 fois par an.</p>
          </AccordionItem>
          <AccordionItem key="6" aria-label="Accordion 6" title="Peut-on prendre plusieurs véhicules simultanément ?">
            <p>L’abonnement est conçu que pour un seul véhicule à la fois.</p>
          </AccordionItem>
          <AccordionItem key="7" aria-label="Accordion 7" title="Dans quels pays sont immatriculés les véhicules ?">
            <p>L’ensemble de notre flotte de véhicule est immatriculé en France.</p>
          </AccordionItem>
          <AccordionItem key="8" aria-label="Accordion 8" title="Combien de conducteurs sont inclus dans l’abonnement ?">
            <p>Avec l’abonnement d’EvoRent, 2 conducteurs du même foyer sont inclus dans l’abonnement.</p>
          </AccordionItem>
        </Accordion>
      </div>
      {/* Partie e-mail */}
      <div className="relative isolate overflow-hidden bg-black py-16 sm:py-24 lg:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
            <div className="max-w-xl lg:max-w-lg mt-auto mb-auto">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Vous avez encore des questions ? </h2>
              <p className="mt-4 text-lg leading-8 text-gray-300">
                Prenez contact avec un de nos conseillers concernant nos offres ou bien pour le choix de la formule et le véhicule adequat.
              </p>
            </div>
            <form className="max-w-xl lg:max-w-lg" onSubmit={handleSubmit}>
              <div className="mt-6 flex max-w-md gap-x-4">
               <input required type="text" name="nom" label="Nom" pattern="^[a-zA-ZÀ-ÿ\-']{2,}(?: [a-zA-ZÀ-ÿ\-']{2,})?$" 
                  className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  placeholder="Nom" value={formData.nom} onChange={handleChange}
                />
                <input required id="email-address" name="email"  type="email" autoComplete="email" pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$" 
                  className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  placeholder="E-mail" value={formData.email} onChange={handleChange}
                />                
              </div>
              <div className="mt-6 flex max-w-md gap-x-4">
              <textarea required name="texte" label="Remarques ou questions supplementaires ? N'hesitez pas aussi à préciser quand vous souhaitez être recontacté."
                className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                placeholder="Votre demande" value={formData.texte} onChange={handleChange}
              />
              </div>
              <div className="mt-6 flex max-w-md gap-x-4">
                <Button size="lg" className="bg-gradient-to-tr from-purple-700 to-pink-400 text-white shadow-lg itemshome" radius="full" color="secondary" variant="shadow" type="submit" value="Submit">Envoyer</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal backdrop="blur" isOpen={modalOpen}>
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">Demande d'information envoyé</ModalHeader>
          <ModalBody>
            <p> 
              Le formulaire a bien été envoyé vers nos services. Vous serez recontacter prochainement par un conseillé EvoRent.
            </p>
          </ModalBody>
          <ModalFooter>
            <a href="/abonnement"><Button color="danger" variant="shadow">D'accord</Button></a>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Footer/>
    </>
  );
}

export default Abonnements;